import button from './images/button.png'
import {useEffect, useRef, useState} from "react";
import 'croppie/croppie.css';

export default function ContainedPhotoModule({callback}) {
  // Video stream
  const videoRef = useRef(null);

  // If orientation is portrait, we should use another image
  const [isPortrait, setIsPortrait] = useState(false);

  // Button animation
  const [isTapped, setIsTapped] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    if ('visualViewport' in window) {
      // Use visualViewport height for Safari 13+
      setWindowWidth(window.visualViewport.width);
      setWindowHeight(window.visualViewport.height);
    } else {
      // Fallback for browsers that don't support visualViewport
      setWindowWidth(window.innerWidth || document.documentElement.clientWidth);
      setWindowHeight(window.innerHeight || document.documentElement.clientHeight);
    }
  };

  useEffect(() => {
  }, []);

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: 1280,
          height: 720,
          frameRate: 25,
        }
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const getOrientation = () => {
    if (window.orientation !== undefined) {
      return window.orientation;
    } else {
      return window.screen.orientation.angle;
    }
  }

  const handleOrientationChange = () => {
    setIsPortrait(getOrientation() === 0 || getOrientation() === 180);
  }

  useEffect(() => {
    startCamera();

    handleOrientationChange();
    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleTouchStart = () => {
    setIsTapped(true);
  };

  const handleTouchEnd = () => {
    setIsTapped(false);
  };

  const takePhoto = () => {
    // Take photo from mediaStream
    const canvas = document.createElement('canvas');
    canvas.width = 1280;
    canvas.height = 720;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, 1280, 720);
    const data = canvas.toDataURL('image/png');

    // Feel free to change this
    if (callback) {
      callback(data);
    } else {
      // Then download it
      const a = document.createElement("a");
      a.href = data;
      a.download = "image.png";
      a.click();
    }
  }

  function calculateAndDisplayDistances(overlay) {
    const overlayRect = overlay.getBoundingClientRect();

    return {
      top: overlayRect.top,
      left: overlayRect.left,
      right: overlayRect.right,
      bottom: overlayRect.bottom,
    };
  }

  useEffect(() => {
    const overlay = document.getElementById("overlay");
    let isDragging = false;
    let initialWidth, initialHeight, offsetX, offsetY;
    let currentZoom = 1.0;

    overlay.addEventListener("mousedown", (e) => {
      if (e.target === overlay) {
        isDragging = true;
        offsetX = e.clientX - overlay.getBoundingClientRect().left;
        offsetY = e.clientY - overlay.getBoundingClientRect().top;
        e.preventDefault(); // Prevent the default drag-and-drop behavior
      } else if (e.target.classList.contains("resize-handle")) {
        initialWidth = parseFloat(getComputedStyle(overlay, null).width);
        initialHeight = parseFloat(getComputedStyle(overlay, null).height);
        e.preventDefault();
      }
    });

    overlay.addEventListener("touchstart", (e) => {
      if (e.target === overlay) {
        isDragging = true;
        const touch = e.touches[0];
        offsetX = touch.clientX - overlay.getBoundingClientRect().left;
        offsetY = touch.clientY - overlay.getBoundingClientRect().top;
        e.preventDefault(); // Prevent default touch event
      }
    });

    document.addEventListener("touchmove", (e) => {
      if (isDragging) {
        const touch = e.touches[0];
        overlay.style.left = touch.clientX - offsetX / currentZoom + "px";
        overlay.style.top = touch.clientY - offsetY / currentZoom + "px";
        connectCoordinatesToCorners(calculateAndDisplayDistances(overlay));
      }
    });

    document.addEventListener("mouseup", () => {
      isDragging = false;
    });

    document.addEventListener("touchend", () => {
      isDragging = false;
    });

// Function to handle pinch-to-zoom
    let initialPinchDistance = 0;
    let currentPinchDistance = 0;

    overlay.addEventListener("touchstart", (e) => {
      if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        initialPinchDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
      }
    });

    overlay.addEventListener("touchmove", (e) => {
      if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        currentPinchDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );

        const zoomDelta = currentPinchDistance - initialPinchDistance;
        zoomOverlay(zoomDelta);
        initialPinchDistance = currentPinchDistance;
      }
    });

// Function to handle mouse scroll zoom
    overlay.addEventListener("wheel", (e) => {
      const zoomDelta = e.deltaY * -0.01; // Adjust the multiplication factor to control zoom speed
      zoomOverlay(zoomDelta);
      e.preventDefault();
    });

    function zoomOverlay(delta) {
      const zoomFactor = 0.01; // Adjust the zoom factor as needed
      currentZoom += delta * zoomFactor;
      currentZoom = Math.min(1.7, Math.max(0.8, currentZoom));
      overlay.style.transform = `scale(${currentZoom})`;

      // Calculate and display distances after zoom
      connectCoordinatesToCorners(calculateAndDisplayDistances(overlay));
    }
  }, [connectCoordinatesToCorners])

  useEffect(() => {
    const overlay = document.getElementById("overlay");

    // Function to center the overlay initially and set the initial width and height
    function initializeOverlay() {
      const initialWidth = isPortrait ? 180 : 320;
      const initialHeight = isPortrait ? 320 : 180;

      const centerX = (windowWidth - initialWidth) / 2;
      const centerY = (windowHeight - initialHeight) / 2;

      overlay.style.transform = "";
      overlay.style.left = centerX + "px";
      overlay.style.top = centerY + "px";
      overlay.style.width = initialWidth + "px";
      overlay.style.height = initialHeight + "px";
    }

    // Initialize the overlay with the desired width and height
    // Testing
    // setTimeout(initializeOverlay, 1000);
    // setTimeout(() => {
    //   connectCoordinatesToCorners(calculateAndDisplayDistances(overlay));
    // }, 2000);

    initializeOverlay();
    connectCoordinatesToCorners(calculateAndDisplayDistances(overlay));
  }, [connectCoordinatesToCorners, isPortrait, windowWidth, windowHeight]);

  function connectCoordinatesToCorners(distances) {
    const topLeft = {
      x: distances.left,
      y: distances.top,
    };

    const topRight = {
      x: distances.right,
      y: distances.top,
    };

    const bottomRight = {
      x: distances.right,
      y: distances.bottom,
    };

    const bottomLeft = {
      x: distances.left,
      y: distances.bottom,
    };

    const topLeftLine = document.getElementById("top-left-line");
    const topRightLine = document.getElementById("top-right-line");
    const bottomRightLine = document.getElementById("bottom-right-line");
    const bottomLeftLine = document.getElementById("bottom-left-line");
    const topLeftCoordinate = document.getElementById("top-left-coordinate");
    const topRightCoordinate = document.getElementById("top-right-coordinate");
    const bottomRightCoordinate = document.getElementById("bottom-right-coordinate");
    const bottomLeftCoordinate = document.getElementById("bottom-left-coordinate");

    // the lines should start in the corner of the svg

    topLeftLine.setAttribute(
      "d",
      `M 0 0 L ${topLeft.x} ${topLeft.y}`
    );

    topRightLine.setAttribute(
      "d",
      `M ${windowWidth} 0 L ${topRight.x} ${topRight.y}`
    );

    bottomRightLine.setAttribute(
      "d",
      `M ${windowWidth} ${windowHeight} L ${bottomRight.x} ${bottomRight.y}`
    );

    bottomLeftLine.setAttribute(
      "d",
      `M 0 ${windowHeight} L ${bottomLeft.x} ${bottomLeft.y}`
    );

    // the coordinates should be a circle with a radius of 10px

    topLeftCoordinate.setAttribute(
      "cx",
      topLeft.x
    );
    topLeftCoordinate.setAttribute(
      "cy",
      topLeft.y
    );
    topLeftCoordinate.setAttribute(
      "r",
      '10px'
    );

    topRightCoordinate.setAttribute(
      "cx",
      topRight.x
    );
    topRightCoordinate.setAttribute(
      "cy",
      topRight.y
    );
    topRightCoordinate.setAttribute(
      "r",
      '10px'
    );

    bottomRightCoordinate.setAttribute(
      "cx",
      bottomRight.x
    );
    bottomRightCoordinate.setAttribute(
      "cy",
      bottomRight.y
    );
    bottomRightCoordinate.setAttribute(
      "r",
      '10px'
    );

    bottomLeftCoordinate.setAttribute(
      "cx",
      bottomLeft.x
    );
    bottomLeftCoordinate.setAttribute(
      "cy",
      bottomLeft.y
    );
    bottomLeftCoordinate.setAttribute(
      "r",
      '10px'
    );
  }

  return (
    <div style={{
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <button
        className={`take-photo-button ${isTapped ? "tapped" : ""} ${isPortrait ? 'portrait' : ''}`}
        onClick={takePhoto}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}>
        <img src={button} alt="Button"/>
      </button>
      <div className="video-container">
        <video
          id="video"
          autoPlay playsInline style={{width: '100vw', height: '100vh', objectFit: 'cover'}}
          ref={videoRef}/>
        <div id="overlay" className="overlay"></div>
        <svg id="svg" className="svg" viewBox={`0 0 ${windowWidth} ${windowHeight}`}>
          <path id="top-left-line" className="line"/>
          <path id="top-right-line" className="line"/>
          <path id="bottom-right-line" className="line"/>
          <path id="bottom-left-line" className="line"/>
          <circle id="top-left-coordinate" className="coordinate"/>
          <circle id="top-right-coordinate" className="coordinate"/>
          <circle id="bottom-right-coordinate" className="coordinate"/>
          <circle id="bottom-left-coordinate" className="coordinate"/>
        </svg>
      </div>
    </div>
  );
}
